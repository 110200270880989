<template>
  <div class="warp">
    <!-- <div class="top">logo</div> -->
    <div class="main">
      <h3>清运分类,重量信息</h3>
      <van-form @submit="onSubmit">
        <!-- 重量 -->
        <van-field
          v-for="(item, index) in itemList"
          :key="index"
          label-width="150"
          right-icon="arrow"
          readonly
          clickable
          name="pickerzl"
          placeholder="点击选择重量"
          :value="item.weightRange ? item.weightRange : undefined"
          :label="item.nameToken"
          @click="dropBoxzl(index)"
          :rules="[{ required: true, message: '请选择重量' }]"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <!-- 时间 -->
        <div class="dropBoxsj">
          <van-field
            label-width="70"
            readonly
            clickable
            name="pickersj"
            label="预约时间"
          />
          <div class="dropBoxdw" v-if="itemList[0].weightRange == '1桶' || itemList[0].weightRange == '2桶'">
            <div class="right active">指定时间上门</div>
          </div>
          <div class="dropBoxdw" v-else>
            <div class="left" :class="classActive1 ? 'active' : ''" @click="dropBoxjksm">{{appointmentjksm}}</div>
            <div class="right" :class="classActive2 ? 'active' : ''" @click="dropBoxyysj">{{appointmentyysj}}</div>
          </div>
        </div>
        <van-popup v-model="showPickersj" closeable position="bottom" :style="{ height: '60%' }">
          <div class="showVisiting">
            <van-tree-select
              :items="itemListsj"
              :active-id.sync="activeId"
              :main-active-index.sync="activeIndex"
              @click-item="itemListsjClick"
            />
          </div>
        </van-popup>
        <!-- 地址 -->
        <van-field
          label-width="120"
          readonly
          clickable
          right-icon="arrow"
          name="pickersj"
          :value="this.$route.query.address ? this.$route.query.address : orderAddress "
          placeholder="点击选择清运地址"
          label="清运地址"
          @click="dropBoxdz"
          :rules="[{ required: true, message: '请选择清运地址' }]"
        />
        <!-- 备注 -->
        <van-field
          v-model="query.orderRemark"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          maxlength="50"
          placeholder="请输入备注"
          show-word-limit
        />
        <div class="btn">
          <van-button round block type="info" native-type="submit">填好了,提交预约</van-button>
        </div>
      </van-form>
      <van-dialog v-model="isShowDialog">
        <div style="padding: 15px; font-size: 18px; line-height: 27px;">{{dialogMsg}}</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { querymoreInfo } from '@/api/order.js'
import { categoryId, orderplace, addressEdit } from '@/api/notice.js'
import { Toast } from 'vant'
import { Dialog } from 'vant'  
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      isShowDialog: false,
      dialogMsg: '',
      timeout: false,
      classActive1: true, // 默认选择尽快上门
      classActive2: false, // 默认选择尽快上门
      itemList: [], // 保存上一级页面传递的子分类
      itemIndex: 0, // 保存重量索引
      showPicker: false, // 默认隐藏重量弹框
      columns: [ // 重量选择list
        '0-0.25吨',
        '0.25-0.5吨',
        '0.5-1吨',
        '0-1吨',
        '1-2吨',
        '2-3吨',
        '3-4吨',
        '4-5吨',
        '5-6吨',
        '6-7吨',
        '7-8吨',
        '8-9吨',
        '9-10吨',
        '10-11吨',
        '11-12吨',
        '12-13吨',
        '13-14吨',
        '14-15吨',
        '15吨以上'
      ],
      query: { // 预约下单参数
        categoryName: undefined, // 总分类名称
        categoryId: undefined, // 总分类id
        appointmentBeginTime: undefined, // 预约开始时间
        appointmentEndTime: undefined, // 预约结束时间
        addressId: undefined, // 地址id
        orderRemark: undefined, // 订单备注
        list: [ // 分类id + 重量范围
          // {
          //   // categoryId: undefined, // 分类id
          //   // weightRange: undefined, // 重量范围
          // }
        ],
      },
      datarq: undefined, // 保存日期
      activeId: 1, // 表示右侧高亮选项的 id
      activeIndex: 0, // 表示左侧高亮选项的索引s
      appointmentjksm: '尽快上门',
      appointmentyysj: '预约时间',
      showPickersj: false, // 默认隐藏时间弹框
      itemListsj: [ // 上门时间弹出层分类显示所需的数据
        // {
        //   text: '今天',
        //   children: [
        //     { id: 1, text: '07:00-09:00' },
        //     { id: 2, text: '09:00-11:00' },
        //     { id: 4, text: '13:00-15:00' },
        //     { id: 5, text: '15:00-17:00' },
        //     { id: 6, text: '17:00-19:00' }
        //   ]
        // },
        {
          text: '明天',
          children: [
            { id: 1, text: '07:00-09:00' },
            { id: 2, text: '09:00-11:00' },
            { id: 4, text: '13:00-15:00' },
            { id: 5, text: '15:00-17:00' },
            { id: 6, text: '17:00-19:00' }
          ]
        },
        {
          text: '后天',
          children: [
            { id: 1, text: '07:00-09:00' },
            { id: 2, text: '09:00-11:00' },
            { id: 4, text: '13:00-15:00' },
            { id: 5, text: '15:00-17:00' },
            { id: 6, text: '17:00-19:00' }
          ]
        }
      ],
      CurrentHour: undefined, // 当前小时
      orderAddress: undefined, // 保存默认地址
      dxcomSort: undefined,
    }
  },
  created() {
    this.addressEdit()
    this.itemList = this.$route.query.itemList
    this.query.categoryId = this.$route.query.categoryId
  },
  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },
  methods: {
    querymoreInfo() { // 获取企业基本资料查看
      querymoreInfo().then(res => {
        if (res.data.code === 200) {
          this.dxcomSort = res.data.data.more.comSort
          if (this.dxcomSort == 2) {
            this.columns = ['1桶', '2桶', '0-1吨']
          }
        }
      })
    },
    addressEdit() { // 获取默认回收地址
      const defaults = 'default'
      addressEdit(defaults).then(res => {
        if (res.data.code === 200) {
          if (res.data.data) {
            this.query.addressId = res.data.data.id
            this.orderAddress = res.data.data.orderAddress
            this.querymoreInfo()
          }
        }
      })
    },
    dropBoxzl(index) { // 点击显示选择重量弹框
      this.itemIndex = index
      this.showPicker = true
    },
    onConfirm(value) { // 点击确认选择重量
      this.itemList[this.itemIndex].weightRange = value
      this.showPicker = false;
    },
    getDatefuzhi() { // 获取当前时间赋值给预约下单的开始和结束
      let myDate = new Date()
      let year = myDate.getFullYear() // 取得4位数的年份
      let month = myDate.getMonth()+1 // js从0开始取
      let date = myDate.getDate() // 日期
      let hours = myDate.getHours() // 获得小时
      hours = hours < 10 ? ('0' + hours) : hours
      let hours1 = myDate.getHours() + 1 // 获得小时
      hours1 = hours1 < 10 ? ('0' + hours1) : hours1
      let min = myDate.getMinutes() // 获得分钟
      min = min < 10 ? ('0' + min) : min
      this.query.appointmentBeginTime = year + '/' + month + '/' + date + ' ' + hours + ':' + min
      this.query.appointmentEndTime =  year + '/' + month + '/' + date + ' ' + hours1 + ':' + min
    },
    dropBoxjksm() { // 尽快上门
      this.getDatefuzhi()
      this.classActive1 = true
      this.classActive2 = false
      this.appointmentyysj = '预约时间'
    },
    // getCurrentDate () { // 获取当前时间的小时
    //   var now = new Date()
    //   var hour = now.getHours() // 得到小时
    //   if (hour < 10) hour = '0' + hour
    //   this.CurrentHour = hour
    //   return this.CurrentHour
    // },
    dropBoxyysj() { // 点击显示选择时间
      // this.getCurrentDate()
      this.showPickersj = true
      // this.itemListsj[0].children = this.itemListsj[0].children.filter(item => {
      //   return parseInt(item.text.substring(6, 8)) > parseInt(this.CurrentHour)
      // })
    },
    itemListsjClick(data) { // 选择上门时间
      this.classActive1 = false
      this.classActive2 = true
      const myDate = new Date()
      if (this.activeIndex === 1) {
        myDate.setDate(myDate.getDate() + 2)
        this.datarq = myDate.toLocaleDateString()
      } else if (this.activeIndex === 3) {
        myDate.setDate(myDate.getDate() + 3)
        this.datarq = myDate.toLocaleDateString()
      } else {
        myDate.setDate(myDate.getDate() + 1)
        this.datarq = myDate.toLocaleDateString()
      }
      this.appointmentyysj = this.datarq.slice(5) + ' ' + data.text
      this.query.appointmentBeginTime = this.datarq + ' ' + data.text.slice(0, 5)
      this.query.appointmentEndTime = this.datarq + ' ' + data.text.slice(6)
      this.showPickersj = false
    },
    dropBoxdz() { // 跳转选择地址
      this.$router.push({path: 'Addresslist', query: {order: true}})
    },
    onSubmit() { // 立即预约下单
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        if (this.classActive1) {
          this.getDatefuzhi()
        }
        this.query.list = this.itemList.map(item => {
          return {
            categoryId: item.id, // 分类id
            weightRange: item.weightRange, // 重量范围
          }
        })
        if (this.query.list[0].weightRange == '1桶' || this.query.list[0].weightRange == '2桶') {
          this.query.appointmentBeginTime = undefined
          this.query.appointmentEndTime =  undefined
        }
        this.query.categoryName = this.itemList[0].nameToken
        if (this.$route.query.addressId) {
          this.query.addressId = this.$route.query.addressId
        }
        orderplace(this.query).then(res => {
          if (res.data.code === 200) {
            Toast('下单成功')
            this.$router.push({path: 'Myorder'})
          } else {
            this.isShowDialog = true
            this.dialogMsg = res.data.msg
          }
        })
      }, 1000)
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  // .top {
  //   height: 150px;
  //   background-color: #fff;
  // }
  .main {
    background-color: #fff;
    padding-top: 15px;
    h3 {
      margin-left: 15px;
      margin-bottom: 10px;
    }
    .dropBoxsj {
      position: relative;
      .dropBoxdw {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-around;
        height: 27px;
        font-size: 12px;
        text-align: center;
        border-radius: 15px;
        border: 1px solid #0087f0;
        overflow: hidden;
        .left, .right {
          line-height: 27px;
          padding: 0 7px;
        }
        .active {
          color: #fff;
          background-color: #0087f0;
        }
      }
    }
  }
}
.showVisiting {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 50px;
  box-sizing: border-box;
  border-top: 1px solid #ddd;
}
::v-deep .van-tree-select {
  height: 100% !important;
}
::v-deep .van-field__control {
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
